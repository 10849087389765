body {
  color: #6F6F6F;
  background-color: #F8F8F8;
  font-family: 'Poppins', sans-serif;
  flex-direction: column;
}

a {
  color: #4196FB;
}

.leaflet-container {
  width: 100%;
  min-height: 550px;
  height: calc(100vh - 300px);
  background-color: #F8F8F8!important;
}

.leaflet-tile-pane {
  opacity: 0;
}

.leaflet-tooltip {
  background-color: #717171 !important;
  border-color: #717171 !important;
  color: #fff !important;
}

.leaflet-tooltip-top:before {
  border-top-color: #717171 !important;
}

.leaflet-popup-close-button {
  display: none;
}

.leaflet-container :focus {
  outline: none;
}

/* Utils */
.h-100 {
  height: 100%;
}

.container {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}